import React, { Component } from "react";

import AboutSection from "../components/AboutUs/AboutUs2";
import ContactSection from "../components/Contact";
import FaqSection from "../components/Faq";
import FeatureSection from "../components/Features/Feature3";
import FooterSection from "../components/Footer";
import Header from "../components/Header/header";
import HeroSection from "../components/HeroSection/HeroSection2";
import PricingSection from "../components/Pricing";
import PromoSection from "../components/PromoSection/Promo2";
import Screenshots from "../components/Screenshots";
import SubscribeSection from "../components/Subscribe";
import TakeoutFeatures from "../components/TakeoutFeatures/TakeoutFeatures2";
import TeamMember from "../components/TeamMember";
import TestimonialTwo from "../components/testimonial/TestimonialTwo";
import TrustedCompany from "../components/TrustedCompany/TrustedCompany";
import VideoSection from "../components/Video";
import TestimonialsPage from "../components/TakeoutFeatures/TakeoutFeatures2";

class Theme extends Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <div className="main">
          <HeroSection />
          <TrustedCompany />
          <FeatureSection />
          <PromoSection />
          <AboutSection />
          {/* <TakeoutFeatures /> */}
          
          {/* <VideoSection showDownloadBtn={true} /> */}
          <PricingSection showTitle={true} hideFaq={true} />
          <TestimonialTwo hasBg={false} />
          {/* <TestimonialsPage /> */}
          {/* <Screenshots /> */}
          <FaqSection />
          <TeamMember />
          <ContactSection />
          <TrustedCompany />
        </div>
        {/* <SubscribeSection /> */}
        <FooterSection noSubscription={true} />
      </React.Fragment>
    );
  }
}

export default Theme;

import React from "react";

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section id="about" className="about-us ptb-100 gray-light-bg">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <span className="badge badge-primary badge-pill">
                  Features
                </span>
                <h2 className="mt-4">Easy menu management</h2>
                <p className="mb-4 lh-190">
                  Add or remove menu items, automatically start/stop happy hour mark them offline, edit prices, create discounts and much more on the fly!
                </p>
                <br></br>
                <a href="#contact" className="btn home-demo-btn page-scroll">Join our network!</a>
              </div>
              <div className="col-md-6">
                <div className="about-content-right">
                  <img
                    src="img/manage-menu.png"
                    width="100%"
                    alt="about us"
                    className="w-500 img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="about" className="about-us ptb-100 gray-light-bg">
          <div className="container">
            <div className="row align-items-center">
            <div className="col-md-6">
                <div className="about-content-right">
                  <img
                    src="img/manage-orders.png"
                    width="500"
                    alt="about us"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <span className="badge badge-primary badge-pill">
                  Feature
                </span>
                <h2 className="mt-4">Manage orders with breeze</h2>
                <p className="mb-4 lh-190">
                  Manage all dine-in and takeout orders on your phone or tablet wherever you are.
                </p>
                <br></br>
                <a href="#contact" className="btn home-demo-btn page-scroll">Join our network!</a>
              </div>
            </div>
          </div>
        </section>
        <section id="about" className="about-us ptb-100 gray-light-bg">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <span className="badge badge-primary badge-pill">
                  Feature
                </span>
                <h2 className="mt-4">View your live floor layout</h2>
                <p className="mb-4 lh-190">
                  Manage your floor layout, add, remove or update tables on the fly and manage your orders table wise.
                </p>
                <br></br>
                <a href="#contact" className="btn home-demo-btn page-scroll">Join our network!</a>
              </div>
              <div className="col-md-6">
                <div className="about-content-right">
                  <img
                    src="img/floor-layout.png"
                    width="100%"
                    alt="about us"
                    className="w-500 img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="about" className="about-us ptb-100 gray-light-bg">
          <div className="container">
            <div className="row align-items-center">
            <div className="col-md-6">
                <div className="about-content-right">
                  <img
                    src="img/split-bills.png"
                    width="500"
                    alt="about us"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <span className="badge badge-primary badge-pill">
                  Feature
                </span>
                <h2 className="mt-4">Split bills for your guests</h2>
                <p className="mb-4 lh-190">
                  Dinify has all the gear in place to help your guest place their order in a breeze and automatize your time-consuming process of taking orders manually and collecting payments.
                </p>
                <ul className="list-unstyled">
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">Accept takeout orders without paying any commission</p>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">
                          Eliminate ordering queue and and reduce line ups
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">
                          Collect orders from your social media pages and your website
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">
                          Set the ETA and we notify your guests with a text notification
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">
                          Collect payments upfront for all takeout orders - we even support Apple Pay and Google Pay.
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
                <br></br>
                <a href="#contact" className="btn home-demo-btn page-scroll">Join our network!</a>
              </div>
            </div>
          </div>
        </section>
        
      </React.Fragment>
    );
  }
}

export default About;

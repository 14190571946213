import React from "react";

class Feature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    function Iframe(props) {
      return (
        <div
          dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
        />
      );
    }
    const demos = {
      soundcloud:
        '<iframe seamless width="100%" height="640" scrolling="yes" frameborder="no" allow="autoplay" src="https://dine-demo.dinify.io/restaurant/61b71a3256ec13307238f1f3/table/7"></iframe>',
    
      plotly:
        '<iframe src="https://codesandbox.io/embed/q7jmjyplvq?fontsize=14" title="Plotly All Graph Types" allow="geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media" style="width:100%; height:500px; border:0; border-radius: 4px; overflow:hidden;" sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"></iframe>'
    };
    return (
      <React.Fragment>
        <section id="features" className="feature-section ptb-100">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-5">
                <div className="download-img d-flex align-bottom justify-content-center">
                    {/* <iframe class='inner' src="https://dine-demo.dinify.io/restaurant/61998556e143ea58d9158d51/table/7" /> */}
                    {/* <Iframe className='frame' iframe={demos["soundcloud"]} allow="autoplay" /> */}
                    <center>
                      <h4>Try it yourself! &#128526;</h4>
                    <div class="frame"><iframe src="https://dine-demo.dinify.io/restaurant/61b71a3256ec13307238f1f3/table/7" /></div> 
                    </center>
                    
                  {/* <img
                    src="img/demo-mock.png"
                    alt="download"
                    className="img-fluid outer"
                  /> */}
                </div>
              </div>
              <div className="col-md-7">
                <div className="feature-contents section-heading">
                  <h2>
                    Dinify fits all your business needs<br />
                    <span>Say nah to commissions!</span>
                  </h2>
                  <p>
                    Our mission is to empower local businesses by helping them keep more of their hard earned money and hence we don't believe in the idea of taking hefty commissions out of your profits! Take a look at our range of offerings:
                  </p>

                  <div className="feature-content-wrap">
                    <ul className="nav nav-tabs feature-tab" data-tabs="tabs">
                      <li className="nav-item">
                        <a
                          className="nav-link active h6"
                          href="#tab6-0"
                          data-toggle="tab"
                        >
                          <span className="ti-face-smile"></span>
                          Free Menus
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link h6"
                          href="#tab6-1"
                          data-toggle="tab"
                        >
                          <span className="ti-face-smile"></span>
                          Dine-in
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link h6"
                          href="#tab6-2"
                          data-toggle="tab"
                        >
                          <span className="ti-folder"></span>
                          Takeout
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link h6"
                          href="#tab6-3"
                          data-toggle="tab"
                        >
                          <span className="ti-bar-chart"></span>
                          Multi-locations
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content feature-tab-content">
                    <div className="tab-pane active" id="tab6-0">
                        <ul className="list-unstyled">
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">Go green! Zero paper/plastic and money waste on reprinting menus!</p>
                              </div>
                            </div>
                          </li>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">
                                Instant menu changes on demand.
                                </p>
                              </div>
                            </div>
                          </li>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">
                                Set daily specials or discount on items on the fly!
                                </p>
                              </div>
                            </div>
                          </li>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">
                                Dynamic menus / dynamic pricing (ask us about this powerful free offering!)
                                </p>
                              </div>
                            </div>
                          </li>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">
                                Let your guests view your menu in their own langauge (yes, we support all major languages!).
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-pane" id="tab6-1">
                        <ul className="list-unstyled">
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">Reduce table turn around = $$$</p>
                              </div>
                            </div>
                          </li>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">
                                Instantly update pricing or menu items.
                                </p>
                              </div>
                            </div>
                          </li>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">
                                Set daily specials or discount on items on the fly!
                                </p>
                              </div>
                            </div>
                          </li>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">
                                Add items to your guest's tab with hybrid ordering
                                </p>
                              </div>
                            </div>
                          </li>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">
                                Split or merge guest tabs and seamlessly collect payment on your payment device*.
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-pane" id="tab6-2">
                        <ul className="list-unstyled">
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">Accept takeout orders without paying any commission</p>
                              </div>
                            </div>
                          </li>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">
                                Eliminate ordering queue and and reduce line ups
                                </p>
                              </div>
                            </div>
                          </li>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">
                                Collect orders from your social media pages and your website
                                </p>
                              </div>
                            </div>
                          </li>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">
                                Set the ETA and we notify your guests with a text notification
                                </p>
                              </div>
                            </div>
                          </li>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">
                                Collect payments upfront for all takeout orders - we even support Apple Pay and Google Pay.
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-pane" id="tab6-3">
                        <div className="row">
                          <div className="col single-feature mb-4">
                            <div className="d-flex align-items-center mb-2">
                              <span className="ti-bar-chart rounded mr-3 icon icon-color-2"></span>
                              <h5 className="mb-0">One dashboard</h5>
                            </div>
                            <p>
                              Manage all your locations from one dashboard!
                            </p>
                          </div>
                          <div className="col single-feature mb-4">
                            <div className="d-flex align-items-center mb-2">
                              <span className="ti-stats-up rounded mr-3 icon icon-color-3"></span>
                              <h5 className="mb-0">Product analytics</h5>
                            </div>
                            <p>
                              Get charts and reports of sales across your locations.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="tab6-4">
                        <p>
                          I have, to push possibilities, to show people, this is
                          the level that things could be at. So when you get
                          something that has the name Kanye West on it, it’s
                          supposed to be pushing the furthest possibilities. I
                          will be the leader of a company that ends up being
                          worth billions of dollars, because I got the answers.
                          I understand culture. I am the nucleus.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Feature;

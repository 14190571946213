module.exports = {
  hero: {
    title: "Dinify",
    description:
      "We are helping local restaurants in post pandemic recovery with our revolutionary next-generation technology to help during this crucial time of front staff shortage and help eliminate unwanted touch points between guests and servers keeping your staff worry free!",
    bgImage: "img/app-hero-bg.jpg"
  },
  promo: {
    title: "Why small business owners love Dinify?",
    description:
      "Following reasons show advantages of adding AgencyCo to your lead pages, demos and checkouts",
    items: [
      {
        title: "Clean Design",
        description:
          "Increase sales by showing true dynamics of your website",
        icon: "ti-vector text-white"
      },
      {
        title: "Secure Data",
        description:
          "Build your online store’s trust using Social Proof & Urgency",
        icon: "ti-lock text-white"
      },
      {
        title: "Retina Ready",
        description:
          "Realize importance of social proof in customer’s purchase decision",
        icon: "ti-eye text-white"
      }
    ]
  },
  aboutUs: {
    title: {
      bold: "Use your android or ios device",
      normal: ""
    },
    description:
      "Following reasons show advantages of adding AgencyCo to your lead pages, demos and checkouts",
    items: [
      {
        title: "Clean Design",
        description:
          "Increase sales by showing true dynamics of your website",
        icon: "ti-vector text-white"
      },
      {
        title: "Secure Data",
        description:
          "Build your online store’s trust using Social Proof & Urgency",
        icon: "ti-lock text-white"
      },
      {
        title: "Retina Ready",
        description:
          "Realize importance of social proof in customer’s purchase decision",
        icon: "ti-eye text-white"
      }
    ]
  },
  price: {
    title: "Pricing Packages",
    description:
      "Uniquely maintain efficient expertise whereas cross functional deliverables. Continually leverage other's competitive mind share whereas enabled channels. ",
    packages: [
      {
        price: "$39",
        license: "Basic",
        isFeatured: false,
        features: [
          "$1.00 fee per guest order",
          "Digital Menu Engineering",
          "Direct integration to all printers",
          "Auto QR Code Generation",
          "Email support"
        ]
      },
      {
        price: "$99",
        license: "Standard",
        isFeatured: true,
        features: [
          "$0.60 fee per guest order",
          "All features in Basic Plan",
          "Interactive data analytics",
          "Email & Phone Support"
        ]
      },
      {
        price: "$149",
        license: "Enterprise",
        isFeatured: false,
        features: [
          "$0.50 fee per order",
          "All features in Standard Plan",
          "Staff ordering on behalf of guest",
          "Email / Chat / Phone Support"
        ]
      }
    ]
  },
  testimonial: {
    title: "What customer say about us",
    description:
      'Rapidiously morph transparent internal or "organic" sources whereas resource sucking e-business. Conveniently innovate compelling internal.',
    comments: [
      {
        name: "Arabella Ora",
        company: "AgencyCo",
        image: "img/client-2.jpg",
        comment:
          "Completely build backend ROI whereas cross-media metrics. Collaboratively deploy customer directed web-readiness via global testing procedures. Appropriately reinvent distributed innovation without proactive catalysts for change. Distinctively."
      },
      {
        name: "John Charles",
        company: "ThemeTags",
        image: "img/client-1.jpg",
        comment:
          "If you can design one thing you can design everything with Front. Just believe it. Collaboratively repurpose performance based e-commerce without cost. It's beautiful and the coding is done quickly and seamlessly."
      },
      {
        name: "Jeremy Jere",
        company: "WorldCheck",
        image: "img/client-3.jpg",
        comment:
          "The template is really nice and offers quite a large set of options. It's beautiful and the coding is done. If you can design one thing you can design everything with Front. Just believe it."
      },
      {
        name: "Ciaran Kierce",
        company: "AppCo",
        image: "img/client-4.jpg",
        comment:
          "I just wanted to share a quick note and let you know that you guys do a really good job. I’m glad I decided to work with you. It’s really great how easy your websites are to update and manage. I never have any problem at all."
      }
    ]
  },
  contact: {
    title: "Connect with us",
    description:
      "It's very easy to get in touch with us. Just use the contact form or just call or email us!",

    office: "Head Office",
    address: "121 King St, Melbourne VIC 3000, Australia",
    phone: "+1 (236) 509-0705",
    email: "info@dinify.io"
  },
  trustedCompany: [
    "img/stripe.png",
    "img/client-6-color.png",
    "img/client-3-color.png",
    "img/client-4-color.png",
    "img/client-5-color.png"
  ],
  trustedCompanyGray: [
    "img/stripe.png",
    "img/client-2-gray.png",
    "img/client-3-gray.png",
    "img/client-4-gray.png",
    "img/client-5-gray.png"
  ],
  trustedCompanyAboutUs: {
    title: "Our Affiliations",
    description: "The best names in the industry",
    companies: [
      "img/stripe.png",
      "img/aws.png",
      "img/mongo.png",
      "img/clover-connect.svg",
      "img/bcrfa.jpg"
    ]
  },
  teamMember: {
    members: [
      {
        name: "Karan Tongay",
        designation: "Founder/CEO",
        image: "img/karan.png"
      },
      {
        name: "Ninad Tongay",
        designation: "Founder/CTO",
        image: "img/ninad.png"
      },
      {
        name: "Prakriti Sharma",
        designation: "Business Analyst",
        image: "img/prakriti.png"
      },
      {
        name: "Sai Vandana",
        designation: "Business Advisor",
        image: "img/vandana.png"
      },
      {
        name: "Garima Gaba",
        designation: "Business Advisor",
        image: "img/garima.png"
      },
      {
        name: "Roy",
        designation: "Business Advisor",
        image: "img/roy.png"
      },
      {
        name: "Souvik Maitra",
        designation: "Business Advisor",
        image: "img/souvik.png"
      },
      {
        name: "Yugansh Gupta",
        designation: "Business Advisor",
        image: "img/yugansh.png"
      },
      {
        name: "Rahul Kharyal",
        designation: "Instructional Developer",
        image: "img/rahul.png"
      },
      {
        name: "Omkar Godham",
        designation: "Software Engineer",
        image: "img/omkar.png"
      },
      {
        name: "Vaibhav Jogdand",
        designation: "Software Engineer",
        image: "img/vaibhav.jpg"
      },
      {
        name: "Kedar Pawar",
        designation: "Bus. Development Head - India",
        image: "img/kedar.png"
      }
    ]
  },
  video: {
    link: "https://www.youtube.com/watch?v=9No-FiEInLA"
  }
};

import axios from "axios";
import React from "react";
import { AiOutlineSafety } from "react-icons/ai";
import { BiSupport } from "react-icons/bi";
import { FaEnvira } from "react-icons/fa";
import { IoRocketOutline } from "react-icons/io5";
import { MdOutlineDining, MdPayment } from "react-icons/md";



class Feature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      restoFeatures: []
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */

     axios.get('https://strapi.dinify.io/restaurant-features')
     .then(response => {
       this.setState({ restoFeatures: response.data});
     })
  }

  render() {

    const icons = [
      <IoRocketOutline className="feature-icon"/>,
      <MdPayment className="feature-icon"/>,
      <BiSupport className="feature-icon"/>,
      <MdOutlineDining className="feature-icon"/>,
      <FaEnvira className="feature-icon"/>,
      <AiOutlineSafety className="feature-icon"/>,
    ];

    return (
      <React.Fragment>
        <div id="restaurants" className={"feature-section ptb-100 gray-light-bg " + (this.props.bgColor && this.props.bgColor === 'gray' ? 'gray-light-bg' : '')}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="section-heading text-center mb-5">
                  <span className="badge badge-primary badge-pill">
                    
                  </span>
                  <h2>
                    You also get... <br />
                  </h2>
                </div>
              </div>
            </div>

            <div className="row row-grid align-items-center">
              <div className="col-lg-4">
                

                {this.state.restoFeatures.map((feature,index)=>{
                
                
                if(index<3){

                return(<div className="d-flex align-items-start mb-5">
                  <div className="pr-4">
                    <div className="icon icon-shape icon-color-3 rounded-circle">
                      <span>{icons[index]}</span>
                    </div>
                  </div>
                  <div className="icon-text">
                    <h5>{feature.heading}</h5>
                    <p className="mb-0">
                    {feature.feature_description}
                    </p>
                  </div>
                </div>
                )
              }


              })}
                
              </div>
              <div className="col-lg-4">
                <div className="position-relative" style={{zIndex: 10}}>
                  <img
                    alt="placeholder"
                    src="img/image-10.png"
                    className="img-center img-fluid"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                

                {this.state.restoFeatures.map((feature,index)=>{
                
                
                if(index>2){

                return(<div className="d-flex align-items-start mb-5">
                  <div className="pr-4">
                    <div className="icon icon-shape icon-color-4 rounded-circle">
                      <span >{icons[index]}</span>
                    </div>
                  </div>
                  <div className="icon-text">
                    <h5>{feature.heading}</h5>
                    <p className="mb-0">
                      {feature.feature_description}
                    </p>
                  </div>
                </div>
                )
              }
              })}
                
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Feature;

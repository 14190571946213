import React from "react";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <footer className="footer-section">
          <div
            className={"footer-top background-img-2 " + (this.props.noSubscription ? 'py-5' : 'pt-150 pb-5')} 
            style={{
              // backgroundImage: "url('img/footer-bg.png')",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center top",
              backgroundSize: "cover"
            }}
          >
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-lg-3 mb-3 mb-lg-0">
                  <div className="footer-nav-wrap text-white">
                    <img
                      src="img/logo-white-1x.png"
                      alt="footer logo"
                      width="70"
                      className="img-fluid mb-3 footer-logo"
                    />
                    <div>

                    <p className="footer-desc">
                    Order your food right through your device and enjoy eating food outside more safe than ever before.
                    </p>
                    </div>

                    <div className="social-list-wrap">
                      <ul className="social-list list-inline list-unstyled">
                        <li className="list-inline-item">
                          <a href="https://www.instagram.com/letsdinify/" target="_blank" title="Instagram">
                            <span className="ti-instagram"></span>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href="https://www.facebook.com/dinify.ca" target="_blank" title="Facebook">
                            <span className="ti-facebook"></span>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href="https://www.linkedin.com/company/dinify-io/" target="_blank" title="LinkedIn">
                            <span className="ti-linkedin"></span>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href="https://wa.me/message/PU2PMIVDEBP4D1" target="_blank" title="Whatsapp">
                            <span className="ti-share"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 ml-auto mb-4 mb-lg-0">
                  <div className="footer-nav-wrap text-white">
                    <h5 className="mb-3 text-white">Other Links</h5>
                    <ul className="list-unstyled">
                      {/* <li className="mb-2">
                        <a href="/#">About Us</a>
                      </li> */}
                      <li className="mb-2">
                        <a href="/#contact">Contact Us</a>
                      </li>
                      {/* <li className="mb-2">
                        <a href="/#">Pricing</a>
                      </li> */}
                      <li className="mb-2">
                        <a href="https://dinify-public-docs.s3.us-west-2.amazonaws.com/privacy_policy.pdf">Privacy Policy</a>
                      </li>
                      <li className="mb-2">
                        <a href="https://dinify-public-docs.s3.us-west-2.amazonaws.com/terms_of_service.pdf">Terms of Service</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 ml-auto mb-4 mb-lg-0">
                  <div className="footer-nav-wrap text-white">
                    <h5 className="mb-3 text-white">Support</h5>
                    <ul className="list-unstyled support-list">
                      {/* <li className="mb-2 d-flex align-items-center">
                        <span className="ti-location-pin mr-2"></span>
                        104 - 6460 Cassie Avenue
                        <br />
                        Burnaby, British Columbia
                        <br />
                        Canada - V5H 2W3
                      </li> */}
                      <li className="mb-2 d-flex align-items-center">
                        <span className="ti-mobile mr-2"></span>{" "}
                        <a href="tel:+12365090705">+1 (236) 509-0705</a>
                      </li>
                      <li className="mb-2 d-flex align-items-center">
                        <span className="ti-email mr-2"></span>
                        <a href="mailto:mail@example.com">info@dinify.io</a>
                      </li>
                      <li className="mb-2 d-flex align-items-center">
                        <span className="ti-world mr-2"></span>
                        <a href="/#">www.dinify.io</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="footer-nav-wrap text-white">
                    <h5 className="mb-3 text-white">Location</h5>
                    <img
                      src="img/map.png"
                      alt="location map"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-bottom gray-light-bg pt-4 pb-4">
            <div className="container">
              <div className="row text-center justify-content-center">
                <div className="col-md-6 col-lg-5">
                  <p className="copyright-text pb-0 mb-0">
                    Copyrights © 2021. All rights reserved by 
                    <a href="/#"> Dinify</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default Footer;

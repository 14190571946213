import axios from "axios";
import React from "react";
import { AiOutlineFieldTime } from "react-icons/ai";
import { MdOutlineRestaurantMenu, MdPayment, MdPhoneIphone } from "react-icons/md";
import { connect } from "react-redux";



class PromoSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      guestFeatures: []
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
     axios.get('https://strapi.dinify.io/guest-features')
     .then(response => {
       this.setState({ guestFeatures: response.data});
     })

  }


  

  render() {

    let icons = [
      <MdPhoneIphone className="icon-md color-secondary"/>,
      <MdPayment className="icon-md color-secondary"/>,
      <MdOutlineRestaurantMenu className="icon-md color-secondary"/>,
      <AiOutlineFieldTime className="icon-md color-secondary"/>,
    ];



    return (
      <React.Fragment>
        <section id="future" className={"promo-section ptb-100 " + (this.props.removeTopMargin ? '' : 'mt-0')}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="section-heading text-center mb-5">
                  {/* <span className="badge badge-primary badge-pill">
                    Best features
                  </span> */}
                  <h2>
                    THE FUTURE OF DINING IS HERE<br />
                  </h2>
                  <p className="h5 mt-3 mb-6">
                    Convenience of having your digital menu on guests' devices and right away placing the order to your restaurant printers. <br></br>
                    <br></br>
                    <b>All under same tab! :)</b>
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              {this.state.guestFeatures.map((feature,index)=>{
                // return(<>
                // <div className="col-lg-3 col-sm-6 mb-lg-0">
                // <div className="card single-promo-card single-promo-hover">
                //   <div className="card-body">
                //     <div className="pb-2">
                //       {/* <span className="ti-mobile icon-md color-secondary"></span> */}
                //       <span>
                //         {icons[index]}
                //       </span>
                //     </div>
                //     <div className="pt-2 pb-3">
                //       <h5>{feature.Heading}</h5>
                //       <p className="text-muted mb-0">
                //         {feature.feature_description}
                //       </p>
                //     </div>
                //   </div>
                // </div>
                // </div></>
                // )

              })}
              
              <div className="position-relative" style={{zIndex: 10}}>
                  <img
                    alt="placeholder"
                    src="img/guests.png"
                    className="img-center img-fluid"
                  />
                </div>
              
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  state
}))(PromoSection);

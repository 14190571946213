import React, { Component } from "react";
import AboutSection from "../components/AboutUs/AboutUs5";
import ContactSection from "../components/Contact";
import FeatureSection from "../components/Features/Feature2";
import FooterSection from "../components/Footer";
import Header from "../components/Header/header";
import HeroSection from "../components/HeroSection/HeroSection1";
import PricingSection from "../components/Pricing";
import PromoSection from "../components/PromoSection/Promo2";
import SubscribeSection from "../components/Subscribe";
import TrustedCompany from "../components/TrustedCompany/TrustedCompany";


class Theme extends Component {
  render() {
    return (
      <React.Fragment>
        <Header bgColor="white" />
        <div className="main">
          <HeroSection />
          <PromoSection />
          <FeatureSection />
          <AboutSection />
          {/* <DownloadSection /> */}
          <PricingSection hideFaq={true} />
          {/* <Testimonial /> */}
          {/* <Screenshots /> */}
          {/* <TeamMember /> */}
          <ContactSection />
          <TrustedCompany />
        </div>
        <SubscribeSection />
        <FooterSection />
      </React.Fragment>
    );
  }
}

export default Theme;

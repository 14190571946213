import axios from "axios";
import React from "react";
import { connect } from "react-redux";
import { subscribe } from "../../actions/index";
import _data from "../../data";

class HeroSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hero: {},
      email: "",
      disableBtn: false,
      btnText: "Subscribe",
      title: '',
      desc: ''
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  changeBtnText = btnText => {
    this.setState({ btnText });
  };

  handleFormValueChange(inputName, event) {
    let stateValue = {};
    stateValue[inputName] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState(stateValue);
  }

  handleSubmit(event) {
    event.preventDefault();

    // disable the button
    this.setState({ disableBtn: true });

    // get action
    const subscribeAction = subscribe(this.state);

    // Dispatch the contact from data
    this.props.dispatch(subscribeAction);

    // added delay to change button text to previous
    setTimeout(
      function() {
        // enable the button
        this.setState({ disableBtn: false });

        // change to button name
        this.changeBtnText("Subscribe");

        // get action again to update state
        const subscribeAction = subscribe(this.state);

        // Dispatch the contact from data
        this.props.dispatch(subscribeAction);

        // clear form data
        this.setState({
          email: ""
        });
      }.bind(this),
      3000
    );
  }

  componentDidMount() {
    
    axios.get('https://strapi.dinify.io/display-pages')
    .then(response => {
      this.setState({ title: response.data[0].title });
      this.setState({ desc: response.data[0].description });
    })

    this.setState({
      hero: _data.hero
    });
  }

  render() {
    return (
      <React.Fragment>
        <section
          className="hero-section hero-section-3 pt-100"
          style={{
            // backgroundImage: `url(${this.state.hero.bgImage})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover"
          }}
        >
          <div className="circles">
            <div className="point animated-point-1"></div>
            <div className="point animated-point-2"></div>
            <div className="point animated-point-3"></div>
            <div className="point animated-point-4"></div>
            <div className="point animated-point-5"></div>
            <div className="point animated-point-6"></div>
          </div>
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-6 col-lg-6">
                <div className="hero-content-left ptb-100">
                    <h1>
                    <span>{this.state.title}</span>
                    </h1>
                    <p className="lead">
                    {this.state.desc}
                    </p>

                    <a href="#contact" className="btn solid-btn page-scroll">
                      Connect with us
                    </a>
                  </div>
                {/* <div className="hero-content-left ptb-100">
                  <h1 className="text-black">
                    <span>{this.state.title}</span>
                  </h1>
                  <p className="lead hero-description">
                    {this.state.desc}
                  </p>
                </div> */}
              </div>
              <div className="col-md-5 col-lg-5">
                <div className="animation-one hero-animation-img">
                  <img
                    src="img/app-product.png"
                    alt="app"
                    className="img-fluid hero-product-img"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-img-absolute">
            <img
              src="img/hero-bg-shape-2.svg"
              alt="shape-image"
              className="shape-image"
            />
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  state
}))(HeroSection);

import React from "react";
import { AiOutlineSafety } from "react-icons/ai";
import { BiSupport } from "react-icons/bi";
import { MdPhoneIphone } from "react-icons/md";


class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section id="guests" className="about-us ptb-100">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-6">
                <div className="about-content-left section-heading">
                  <h2>
                  Guests order their food directly through their device
                  </h2>
                  <h5 className='about-sub-heading'>
                    Save their and your time and make them feel more safe than ever before.
                  </h5>

                  <div className="single-feature mb-4">
                    <div className="d-flex align-items-center mb-2">
                      {/* <span className="ti-vector rounded mr-3 icon icon-color-1"></span> */}
                      <span className=" rounded mr-3 icon icon-color-1"><MdPhoneIphone /></span>
                      <h5 className="mb-0">Easy to use</h5>
                    </div>
                    <p>
                      We provide very easy to use application for both IOS & Android to get all the menus of restaurants right in your devices.
                    </p>
                  </div>
                  <div className="single-feature mb-4">
                    <div className="d-flex align-items-center mb-2">
                      <span className=" rounded mr-3 icon icon-color-2"><AiOutlineSafety /></span>
                      <h5 className="mb-0">Safety First</h5>
                    </div>
                    <p>
                      Eliminate need to touch any shared resources such as Menus, bill books and many more
                    </p>
                  </div>

                  <div className="single-feature mb-4">
                    <div className="d-flex align-items-center mb-2">
                      <span className=" rounded mr-3 icon icon-color-3"><BiSupport /></span>
                      <h5 className="mb-0">24/7 Support</h5>
                    </div>
                    <p>
                      Talk with our support team for quick answers on product features, pricing and more.
                    </p>
                  </div>
                  <a href="/#" className="btn solid-btn mt-2">
                    BECOME OUR PARTNER TODAY
                  </a>
                </div>
              </div>
              <div className="col-md-4">
                <div className="about-content-right">
                  <img
                    src="img/screenshot.jpg"
                    alt="about us"
                    className="img-fluid"
                  />
                  {/* <video src={Demo} autoPlay muted loop className="img-fluid demo-video"/> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default About;

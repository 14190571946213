import React from "react";



class TestimonialsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
  return (
    <React.Fragment>
        <section id="about" className="about-us ptb-100 gray-light-bg">
          <div className="container">
            <div className="row align-items-center">
            <div className="col-md-6">
                <div className="about-content-right">
                  <img
                    src="img/dash-1.png"
                    width="500"
                    alt="about us"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <span className="badge badge-primary badge-pill">
                  Takeout
                </span>
                <h2 className="mt-4">Manage orders with breeze</h2>
                <p className="mb-4 lh-190">
                  Dinify has all the gear in place to help your guest place their order in a breeze and automatize your time-consuming process of taking orders manually and collecting payments.
                </p>
                <ul className="list-unstyled">
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">Accept takeout orders without paying any commission</p>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">
                          Eliminate ordering queue and and reduce line ups
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">
                          Collect orders from your social media pages and your website
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">
                          Set the ETA and we notify your guests with a text notification
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">
                          Collect payments upfront for all takeout orders - we even support Apple Pay and Google Pay.
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
                <br></br>
                <a href="#download" className="btn google-play-btn">Join our network!</a>
              </div>
            </div>
          </div>
        </section>
        <section id="about" className="about-us ptb-100 gray-light-bg">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <span className="badge badge-primary badge-pill">
                  Dine-In
                </span>
                <h2 className="mt-4">View your live floor layout</h2>
                <p className="mb-4 lh-190">
                  Dinify has all the gear in place to help your guest place their order in a breeze and automatize your time-consuming process of taking orders manually and collecting payments.
                </p>
                <ul className="list-unstyled">
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">Reduce table turn around = more sales</p>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">
                          Instantly update pricing or menu items.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">
                          Set daily specials or discount on items on the fly!
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">
                          Add items to your guest's tab with hybrid ordering
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">
                          Split or merge guest tabs and seamlessly collect payment on your payment device*.
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
                <br></br>
                <a href="#download" className="btn google-play-btn">Join our network!</a>
              </div>
              <div className="col-md-6">
                <div className="about-content-right">
                  <img
                    src="img/dash-1.png"
                    width="100%"
                    alt="about us"
                    className="w-500 img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="about" className="about-us ptb-100 gray-light-bg">
          <div className="container">
            <div className="row align-items-center">
            <div className="col-md-6">
                <div className="about-content-right">
                  <img
                    src="img/dash-1.png"
                    width="500"
                    alt="about us"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <span className="badge badge-primary badge-pill">
                  Takeout
                </span>
                <h2 className="mt-4">Split bills for your guests</h2>
                <p className="mb-4 lh-190">
                  Dinify has all the gear in place to help your guest place their order in a breeze and automatize your time-consuming process of taking orders manually and collecting payments.
                </p>
                <ul className="list-unstyled">
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">Accept takeout orders without paying any commission</p>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">
                          Eliminate ordering queue and and reduce line ups
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">
                          Collect orders from your social media pages and your website
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">
                          Set the ETA and we notify your guests with a text notification
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">
                          Collect payments upfront for all takeout orders - we even support Apple Pay and Google Pay.
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
                <br></br>
                <a href="#download" className="btn google-play-btn">Join our network!</a>
              </div>
            </div>
          </div>
        </section>
        
      </React.Fragment>
    );
}
}

export default TestimonialsPage;
import React from "react";

class Faq extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section id = 'faqs'
          className="download-section pt-100 background-img"
          style={{
            backgroundImage: "url('img/background-1.jpg')",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover"
          }}
        >
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-lg-6">
                <div
                  id="accordion-1"
                  className="accordion accordion-faq pb-100"
                >
                  <div className="card">
                    <div
                      className="card-header py-4"
                      id="heading-1-1"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapse-1-1"
                      aria-expanded="false"
                      aria-controls="collapse-1-1"
                    >
                      <h6 className="mb-0">
                        <span className="ti-receipt mr-3"></span> How do I setup for multiple locations?
                      </h6>
                    </div>
                    <div
                      id="collapse-1-1"
                      className="collapse show"
                      aria-labelledby="heading-1-1"
                      data-parent="#accordion-1"
                    >
                      <div className="card-body">
                        <p>
                          With Dinify you can manage multiple locations of your business from a single dashboard. Reach out to us for a multi-location personalized demo.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div
                      className="card-header py-4"
                      id="heading-1-2"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapse-1-2"
                      aria-expanded="false"
                      aria-controls="collapse-1-2"
                    >
                      <h6 className="mb-0">
                        <span className="ti-gallery mr-3"></span> Do customers pay on Dinify or in person to Restaurant?
                      </h6>
                    </div>
                    <div
                      id="collapse-1-2"
                      className="collapse"
                      aria-labelledby="heading-1-2"
                      data-parent="#accordion-1"
                    >
                      <div className="card-body">
                        <p>
                          Dinify is so flexible that you can choose if you want to collect payments thorugh Dinify or simply using your payment provider. (Secret: We also integrate with Clover and could most likely beat your transaction rates, ask us how!).
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div
                      className="card-header py-4"
                      id="heading-1-3"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapse-1-3"
                      aria-expanded="false"
                      aria-controls="collapse-1-3"
                    >
                      <h6 className="mb-0">
                        <span className="ti-wallet mr-3"></span> Can I change menu items, pricing and set discounts as I want?
                      </h6>
                    </div>
                    <div
                      id="collapse-1-3"
                      className="collapse"
                      aria-labelledby="heading-1-3"
                      data-parent="#accordion-1"
                    >
                      <div className="card-body">
                        <p>
                          The answer is Yes! With Dinify you get all the flexibility to update or add more menu items, mark them offline temporarily or simply set daily specials, you'll love it when you'll see it.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="download-img d-flex align-items-start">
                  <img
                    src="img/banner-1.png"
                    alt="download"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Faq;

import React, {useState} from "react";
import { connect } from "react-redux";
import { Modal, Button } from "react-modal";
import { SocialIcon } from 'react-social-icons';

class HeroSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {

    function Iframe(props) {
        return (
          <div
            dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
          />
        );
      }
      const links = {
        freeqrcodeform:
          '<iframe seamless width="125%" height="700" scrolling="yes" frameborder="no" allow="autoplay" src="https://docs.google.com/forms/d/e/1FAIpQLScVdF2IY8ARoF7W3F4XuczJCXfRwIjKY3mCc8rHAwMEVPbbRQ/viewform?usp=sf_link"></iframe>',
        };

    return (
      <React.Fragment>
        <section className="hero-section pt-100 background-img"
        style={{
          backgroundImage: "url('img/background-1.jpg')",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover"
        }}>
        <div className="container" style={{opacity: 9
        }}>
            <div className="row align-items-center justify-content-between">
                <div className="col-md-7 col-lg-7">
                    <div className="hero-content-left text-white mt-5">
                        <h3 className="text-red">Restaurant operations simplified!</h3>
                        <a href='https://docs.google.com/forms/d/e/1FAIpQLScVdF2IY8ARoF7W3F4XuczJCXfRwIjKY3mCc8rHAwMEVPbbRQ/viewform?usp=sf_link'><h2 className="text-white"><span>Test drive our solution <br></br><h3 className="text-red">FREE</h3> for 30 days!</span> </h2></a>

                        <p className="lead"><b>Improve SoS (Speed of Service) | Lower operational costs | Increase OE (Operational Efficiency)</b></p>

                        <a href="https://docs.google.com/forms/d/e/1FAIpQLScVdF2IY8ARoF7W3F4XuczJCXfRwIjKY3mCc8rHAwMEVPbbRQ/viewform?usp=sf_link" className="btn home-demo-btn">Sign up now</a>
                        
                        {/* <a href="#features" className="btn google-play-btn page-scroll">Live Demo!</a> */}
                        <br /><br />
                        <div className="App">
                            <div className="social-list-wrap">
                            <ul className="social-list list-inline list-unstyled">
                                <li className="list-inline-item">
                                    <SocialIcon url="https://www.instagram.com/letsdinify/" fgColor="#ffffff" />
                                </li>
                                <li className="list-inline-item">
                                    <SocialIcon url="https://www.facebook.com/dinify.ca" fgColor="#ffffff" />
                                </li>
                                <li className="list-inline-item">
                                    <SocialIcon url="https://www.linkedin.com/company/dinify-io/" fgColor="#ffffff" />
                                </li>
                                <li className="list-inline-item">
                                    <SocialIcon url="https://wa.me/message/PU2PMIVDEBP4D1" fgColor="#ffffff" />
                                </li>
                            </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="col-md-5 col-lg-5">
                <Iframe iframe={links["freeqrcodeform"]} allow="autoplay" />
                </div> */}
                <div className="col-md-6 col-lg-5">
                    <div className="hero-animation-img">
                        <img src="img/banner-1.png" alt="img" className="img-fluid" />
                    </div>
                </div>
            </div>
            
            <div className="row">
                <ul className="list-inline counter-wrap">
                    <li className="list-inline-item">
                        <div className="single-counter text-center">
                            <span>100</span>%
                            <h6>Availability</h6>
                        </div>
                    </li>
                    <li className="list-inline-item">
                        <div className="single-counter text-center">
                            <span>100</span>%
                            <h6>Service</h6>
                        </div>
                    </li>
                    <li className="list-inline-item">
                        <div className="single-counter text-center">
                            <span>100</span>%
                            <h6>Order Success Rate</h6>
                        </div>
                    </li>
                    <li className="list-inline-item">
                        <div className="single-counter text-center">
                            <span>100</span>%
                            <h6>Happy Clients</h6>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </section>
        </React.Fragment>
    );
  }
}

export default connect(state => ({
  state
}))(HeroSection);

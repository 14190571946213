import React from "react";
import OwlCarousel from "react-owl-carousel";

export default function TestimonialTwo({ hasBg }) {

  const options = {
    loop: false,
    margin: 30,
    items: 1,
    nav: true,
    dots: false,
    responsiveClass: true,
    autoplay: false,
    autoplayHoverPause: true,
    lazyLoad: true,
  };
  
  return (
    <>
      <section id ='testimonials'
        className={`testimonial-section ptb-100 ${
          hasBg ? "gray-light-bg " : ""
        }`}
      >
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-6">
              <div className="section-heading mb-5">
                {hasBg ? (
                  <span className="text-uppercase color-secondary sub-title">
                    Our Trusted Clients
                  </span>
                ) : (
                  ""
                )}
                <h2>What Clients Say About Us</h2>
                <p className="lead">
                  Read what our esteemed clients think of us. We are proud of them!
                </p>

                <div className="client-section-wrap d-flex flex-row align-items-center">
                  <ul className="list-inline">
                    {/* <li className="list-inline-item">
                      <img
                        src="img/indian-aroma-logo.png"
                        width="85"
                        alt="client"
                        className="img-fluid"
                      />
                    </li>
                    <li className="list-inline-item">
                      <img
                        src="img/spiceler-logo.jpeg"
                        width="85"
                        alt="client"
                        className="img-fluid"
                      />
                    </li>
                    <li className="list-inline-item">
                      <img
                        src="img/namaste-logo.png"
                        width="85"
                        alt="client"
                        className="img-fluid"
                      />
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <OwlCarousel
                className="owl-carousel owl-theme client-testimonial arrow-indicator"
                {...options}
              >
                <div className="item">
                  <div className="testimonial-quote-wrap">
                    <div className="media author-info mb-3">
                      <div className="author-img mr-3">
                        <img
                          src="img/indian-aroma-logo.png"
                          alt="client"
                          className="img-fluid rounded-circle"
                        />
                      </div>
                      <div className="media-body">
                        <h5 className="mb-0">Mr. Baliram</h5>
                        <span>Indian Aroma - Victoria</span>
                      </div>
                    </div>
                    <div className="client-say">
                      <p>
                        <img
                          src="img/quote.png"
                          alt="quote"
                          className="img-fluid"
                        />
                        We always had a problem of scheduling and staff shortage due to which our guests had long wait times to place order and even to make a payment. Dinify has been really helpful to us and beautifully addressed this problem of ours.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="testimonial-quote-wrap">
                    <div className="media author-info mb-3">
                      <div className="author-img mr-3">
                        <img
                          src="img/spiceler-logo.jpeg"
                          alt="client"
                          className="img-fluid rounded-circle"
                        />
                      </div>
                      <div className="media-body">
                        <h5 className="mb-0">Mr. Javed</h5>
                        <span>Spiceler Indian Cuisine</span>
                      </div>
                    </div>
                    <div className="client-say">
                      <p>
                        <img
                          src="img/quote.png"
                          alt="quote"
                          className="img-fluid"
                        />
                        Dinify offered us 0% commission on takeout and 2 business days money deposit. Its a delight for our customers to use Dinify and even for us its pretty intuitive to use. We also use Dinify for Dine-in.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="testimonial-quote-wrap">
                    <div className="media author-info mb-3">
                      <div className="author-img mr-3">
                        <img
                          src="img/namaste-logo.png"
                          alt="client"
                          className="img-fluid rounded-circle"
                        />
                      </div>
                      <div className="media-body">
                        <h5 className="mb-0">Mr. Manu</h5>
                        <span>Namaste Indian Cuisine</span>
                      </div>
                    </div>
                    <div className="client-say">
                      <p>
                        <img
                          src="img/quote.png"
                          alt="quote"
                          className="img-fluid"
                        />
                        Customers can seamlessly place orders and our staff can focus more on hospitality. Thanks to Dinify!
                      </p>
                    </div>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
